<template>
  <div>
    <h1 class="h2">{{ Title }}</h1>

    <div class="my-5">
      <Btn flat color="primary" icon="backspace" @click="back">戻る</Btn>
      <Btn
        class="ml-3"
        color="info"
        icon="details"
        :disabled="edit || add"
        @click="edit = true"
        >編集</Btn
      >
    </div>
    <v-form ref="shainform" :disabled="!edit && !add" v-if="show">
      <template>
        <div>
          <h2>社員マスタ登録</h2>
        </div>
        <v-text-field
          v-model="shain.code"
          label="社員コード"
          prepend-icon="mdi-account"
          outlined
          :disabled="edit && !add"
          :rules="Required"
        ></v-text-field>
        <v-text-field
          v-model="shain.name1"
          label="社員名１"
          prepend-icon="mdi-account"
          outlined
          :rules="Required"
        ></v-text-field>
        <v-text-field
          v-model="shain.name2"
          label="社員名２"
          prepend-icon="mdi-account"
          outlined
          :rules="Required"
        ></v-text-field>
        <v-tabs v-model="tabs" color="blue">
          <v-tab v-for="item in tabItems" :key="item" :disabled="add">
            {{ item }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabs" class="home pa-3">
          <v-tab-item>
            <v-text-field
              v-model="shain.furi1"
              label="フリガナ１"
              prepend-icon="mdi-account"
              outlined
              :rules="Required"
            ></v-text-field>
            <v-text-field
              v-model="shain.furi2"
              label="フリガナ２"
              prepend-icon="mdi-account"
              outlined
              :rules="Required"
            ></v-text-field>
            <v-autocomplete
              v-model="shain.shozoku"
              :items="shozokuList"
              label="所属"
              prepend-icon="mdi-account-cog"
              outlined
              item-text="name"
              :item-value="item => Number(item.code)"
              :rules="Required"
            ></v-autocomplete>
            <v-autocomplete
              v-model="shain.sex"
              :items="sexList"
              label="性別"
              prepend-icon="mdi-account-cog"
              outlined
              item-text="name"
              :item-value="item => Number(item.code)"
              :rules="Required"
            ></v-autocomplete>
            <DatePicker
              v-model="shain.daybirth"
              label="生年月日"
              prepend-icon="mdi-calendar"
              type="day"
              outlined
              clearable
              :rules="Required"
            />
            <v-autocomplete
              v-model="shain.taimode"
              :items="zaiKbnList"
              label="在籍区分"
              prepend-icon="mdi-account-cog"
              outlined
              item-text="name"
              :item-value="item => Number(item.code)"
              :rules="Required"
            ></v-autocomplete>
            <DatePicker
              v-model="shain.daynyu"
              label="入社年月日"
              prepend-icon="mdi-calendar"
              type="day"
              outlined
              clearable
              :rules="Required"
            />
            <DatePicker
              v-model="shain.daytai"
              label="退社年月日"
              prepend-icon="mdi-calendar"
              type="day"
              outlined
              clearable
              :disabled="!enableField.includes(shain.taimode)"
            />
            <v-text-field
              type="number"
              prepend-icon="mdi-timetable"
              v-model.number="shain.monthKbn"
              label="有給月区分"
              suffix="月"
              min="1"
              max="12"
              :disabled="true"
              v-if="false"
            ></v-text-field>
            <v-text-field
              type="number"
              prepend-icon="mdi-timetable"
              v-model.number="shain.yearInfo"
              label="有給年区分"
              suffix="年"
              min="1"
              :disabled="true"
              v-if="false"
            ></v-text-field>
            <v-autocomplete
              v-model="shain.authority"
              :items="authorList"
              label="権限"
              prepend-icon="mdi-account-cog"
              outlined
              item-text="name"
              item-value="code"
              :rules="Required"
            ></v-autocomplete>
            <v-text-field
              v-model="shain.password"
              label="パスワード"
              prepend-icon="mdi-lock"
              outlined
              :rules="Required"
            ></v-text-field>
            <v-radio-group
              v-model="shain.isValid"
              row
              label="状態"
              :rules="Required"
            >
              <v-radio label="有効" :value="SelectsDelete.Default"></v-radio>
              <v-radio label="無効" :value="SelectsDelete.Delete"></v-radio>
            </v-radio-group>
            <div class="d-flex flex-row-reverse">
              <v-btn
                color="success"
                :disabled="!edit"
                @click="submitShain('基本情報', 'shainform')"
              >
                完了
              </v-btn>
            </div>
          </v-tab-item>
          <v-tab-item>
            <v-autocomplete
              v-model="shain.kkubun"
              :items="salaryList"
              label="給与区分"
              prepend-icon="mdi-account-cog"
              outlined
              item-text="name"
              :item-value="item => Number(item.code)"
            ></v-autocomplete>
            <v-text-field
              type="number"
              prepend-icon="mdi-timetable"
              v-model.number="shain.kyuhtan0"
              label="基本単価"
            ></v-text-field>
            <v-text-field
              type="number"
              prepend-icon="mdi-timetable"
              v-model.number="shain.kyuhtan9"
              label="時間内単価"
            ></v-text-field>
            <v-text-field
              type="number"
              prepend-icon="mdi-timetable"
              v-model.number="shain.kyuhtan10"
              label="時間外単価"
            ></v-text-field>
            <v-text-field
              type="number"
              prepend-icon="mdi-timetable"
              v-model.number="shain.kyuhtan11"
              label="深夜単価"
            ></v-text-field>
            <v-text-field
              type="number"
              prepend-icon="mdi-timetable"
              v-model.number="shain.kyuhtan12"
              label="通勤費(日額)"
            ></v-text-field>
            <div class="d-flex flex-row-reverse">
              <v-btn
                color="success"
                :disabled="!edit"
                @click="submitShain('給与情報', 'shainform')"
              >
                完了
              </v-btn>
            </div>
          </v-tab-item>
          <v-tab-item>
            <v-autocomplete
              v-model="shainExp.dayOver"
              :items="dayOverList"
              label="残業計算(日)"
              prepend-icon="mdi-account-cog"
              outlined
              item-text="name"
              item-value="code"
            ></v-autocomplete>
            <v-autocomplete
              v-model="shainExp.weekWorkStart"
              :items="weekWorkStartList"
              label="残業計算(週)"
              prepend-icon="mdi-account-cog"
              outlined
              item-text="name"
              item-value="code"
            ></v-autocomplete>
            <v-autocomplete
              v-model="shainExp.flexflag"
              :items="flexFlagList"
              label="残業計算(月)"
              prepend-icon="mdi-account-cog"
              outlined
              item-text="name"
              item-value="code"
            ></v-autocomplete>
            <div class="d-flex flex-row-reverse">
              <v-btn
                color="success"
                :disabled="!edit"
                @click="submitShainExpansion('時間計算')"
              >
                完了
              </v-btn>
            </div>
          </v-tab-item>
          <v-tab-item>
            <Btn
              color="info"
              icon="plus"
              text
              @click="addShozoku()"
              :disabled="!edit"
              >所属追加</Btn
            >
            <table class="my-5">
              <thead>
                <tr>
                  <th v-for="(header, i) in subHeaders" :key="i">
                    {{ header }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in this.shainExp.subDetails"
                  :key="index"
                >
                  <td>{{ item.displayName }}</td>
                  <td>
                    <v-text-field
                      v-model.number="item.amount1"
                      type="number"
                      step="1"
                      min="0"
                      suffix="円"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      v-model.number="item.amount2"
                      type="number"
                      step="1"
                      min="0"
                      suffix="円"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      v-model.number="item.amount3"
                      type="number"
                      step="1"
                      min="0"
                      suffix="円"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-checkbox v-model="item.subSpecialCommit"></v-checkbox>
                  </td>
                  <td>
                    <v-icon
                      small
                      class="ml-1"
                      color="error"
                      :disabled="!edit"
                      @click="deleteAction(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="d-flex flex-row-reverse">
              <v-btn
                color="success"
                :disabled="!edit"
                @click="submitShainExpansion('所属別設定')"
              >
                完了
              </v-btn>
            </div>
          </v-tab-item>
          <v-tab-item>
            <label>最終更新日</label>
            <table class="my-5">
              <thead>
                <tr>
                  <th v-for="(header, i) in subHeadersForLastedUpdate" :key="i">
                    {{ header }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in this.shainExpWorkList" :key="index">
                  <!--<td>{{ item.displayName }}</td>-->
                  <td>
                    <label>{{ dayKanjiList[Number(item.weekday) - 1] }}</label>
                  </td>
                  <td>
                    <v-autocomplete
                      v-model="item.shiftPatternId"
                      :items="shiftList"
                      outlined
                      item-text="shiftPatternName"
                      :item-value="item => Number(item.shiftPatternId)"
                    ></v-autocomplete>
                  </td>
                  <td>
                    <label>{{
                      getStartTime(item.shiftPatternId) +
                        getEndTime(item.shiftPatternId)
                    }}</label>
                  </td>
                  <td>
                    <label>{{ getBreakTime(item.shiftPatternId) }}</label>
                  </td>
                  <td>
                    <label>{{
                      getPlannedWorkingHours(item.shiftPatternId)
                    }}</label>
                  </td>
                  <td>
                    <v-row class="align-center">
                      <v-checkbox
                        v-model="item.week1"
                        :label="`１週 `"
                      ></v-checkbox>
                      <v-checkbox
                        v-model="item.week2"
                        :label="`２週 `"
                      ></v-checkbox>
                      <v-checkbox
                        v-model="item.week3"
                        :label="`３週 `"
                      ></v-checkbox>
                      <v-checkbox
                        v-model="item.week4"
                        :label="`４週 `"
                      ></v-checkbox>
                      <v-checkbox
                        v-model="item.week5"
                        :label="`５週 `"
                      ></v-checkbox>
                    </v-row>
                  </td>
                  <td>
                    <v-icon
                      small
                      @click="addShift(item)"
                      class="ml-1"
                      color="success"
                      :disabled="!edit"
                    >
                      mdi-plus
                    </v-icon>
                    <v-icon
                      small
                      @click="removeShift(item)"
                      class="ml-1"
                      color="error"
                      :disabled="!edit"
                    >
                      mdi-minus
                    </v-icon>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="d-flex flex-row-reverse">
              <v-btn
                color="success"
                :disabled="!edit"
                @click="submitShainExpansionWork('現状届')"
              >
                完了
              </v-btn>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </template>
    </v-form>
    <v-form
      ref="shainformwithrestriction"
      :disabled="!edit && !add"
      v-if="restriction"
    >
      <template>
        <div>
          <h2>社員マスタ登録</h2>
        </div>
        <v-text-field
          v-model="shain.code"
          label="社員コード"
          prepend-icon="mdi-account"
          outlined
          :disabled="(edit && !add) || restriction"
          :rules="Required"
        ></v-text-field>
        <v-text-field
          v-model="shain.name1"
          label="社員名１"
          prepend-icon="mdi-account"
          outlined
          :rules="Required"
          :disabled="restriction"
        ></v-text-field>
        <v-text-field
          v-model="shain.name2"
          label="社員名２"
          prepend-icon="mdi-account"
          outlined
          :rules="Required"
          :disabled="restriction"
        ></v-text-field>
        <v-tabs v-model="tabs" color="blue">
          <v-tab
            v-for="item in tabItems"
            :key="item"
            :disabled="add || restriction"
          >
            {{ item }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabs" class="home pa-3">
          <v-tab-item>
            <v-text-field
              v-model="shain.furi1"
              label="フリガナ１"
              prepend-icon="mdi-account"
              outlined
              :rules="Required"
              :disabled="restriction"
            ></v-text-field>
            <v-text-field
              v-model="shain.furi2"
              label="フリガナ２"
              prepend-icon="mdi-account"
              outlined
              :rules="Required"
              :disabled="restriction"
            ></v-text-field>
            <v-autocomplete
              v-model="shain.shozoku"
              :items="shozokuList"
              label="所属"
              prepend-icon="mdi-account-cog"
              outlined
              item-text="name"
              :item-value="item => Number(item.code)"
              :rules="Required"
              :disabled="restriction"
            ></v-autocomplete>
            <v-autocomplete
              v-model="shain.sex"
              :items="sexList"
              label="性別"
              prepend-icon="mdi-account-cog"
              outlined
              item-text="name"
              :item-value="item => Number(item.code)"
              :rules="Required"
              :disabled="restriction"
            ></v-autocomplete>
            <DatePicker
              v-model="shain.daybirth"
              label="生年月日"
              prepend-icon="mdi-calendar"
              type="day"
              outlined
              clearable
              :rules="Required"
              :disabled="restriction"
            />
            <v-autocomplete
              v-model="shain.taimode"
              :items="zaiKbnList"
              label="在籍区分"
              prepend-icon="mdi-account-cog"
              outlined
              item-text="name"
              :item-value="item => Number(item.code)"
              :rules="Required"
              :disabled="restriction"
            ></v-autocomplete>
            <DatePicker
              v-model="shain.daynyu"
              label="入社年月日"
              prepend-icon="mdi-calendar"
              type="day"
              outlined
              clearable
              :rules="Required"
              :disabled="restriction"
            />
            <DatePicker
              v-model="shain.daytai"
              label="退社年月日"
              prepend-icon="mdi-calendar"
              type="day"
              outlined
              clearable
              :disabled="!enableField.includes(shain.taimode) || restriction"
            />
            <v-text-field
              type="number"
              prepend-icon="mdi-timetable"
              v-model.number="shain.monthKbn"
              label="有給月区分"
              suffix="月"
              min="1"
              max="12"
              :disabled="true"
              v-if="false"
            ></v-text-field>
            <v-text-field
              type="number"
              prepend-icon="mdi-timetable"
              v-model.number="shain.yearInfo"
              label="有給年区分"
              suffix="年"
              min="1"
              :disabled="true"
              v-if="false"
            ></v-text-field>
            <v-autocomplete
              v-model="shain.authority"
              :items="authorList"
              label="権限"
              prepend-icon="mdi-account-cog"
              outlined
              item-text="name"
              item-value="code"
              :rules="Required"
              :disabled="restriction"
            ></v-autocomplete>
            <v-text-field
              v-model="shain.password"
              label="パスワード"
              prepend-icon="mdi-lock"
              outlined
              :rules="Required"
            ></v-text-field>
            <v-radio-group
              v-model="shain.isValid"
              row
              label="状態"
              :rules="Required"
              :disabled="restriction"
            >
              <v-radio label="有効" :value="SelectsDelete.Default"></v-radio>
              <v-radio label="無効" :value="SelectsDelete.Delete"></v-radio>
            </v-radio-group>
            <div class="d-flex flex-row-reverse">
              <v-btn
                color="success"
                :disabled="!edit"
                @click="submitShain('基本情報', 'shainformwithrestriction')"
              >
                完了
              </v-btn>
            </div>
          </v-tab-item>
          <v-tab-item>
            <v-autocomplete
              v-model="shain.kkubun"
              :items="salaryList"
              label="給与区分"
              prepend-icon="mdi-account-cog"
              outlined
              item-text="name"
              :item-value="item => Number(item.code)"
            ></v-autocomplete>
            <v-text-field
              type="number"
              prepend-icon="mdi-timetable"
              v-model.number="shain.kyuhtan0"
              label="基本単価"
            ></v-text-field>
            <v-text-field
              type="number"
              prepend-icon="mdi-timetable"
              v-model.number="shain.kyuhtan9"
              label="時間内単価"
            ></v-text-field>
            <v-text-field
              type="number"
              prepend-icon="mdi-timetable"
              v-model.number="shain.kyuhtan10"
              label="時間外単価"
            ></v-text-field>
            <v-text-field
              type="number"
              prepend-icon="mdi-timetable"
              v-model.number="shain.kyuhtan11"
              label="深夜単価"
            ></v-text-field>
            <v-text-field
              type="number"
              prepend-icon="mdi-timetable"
              v-model.number="shain.kyuhtan12"
              label="通勤費(日額)"
            ></v-text-field>
            <div class="d-flex flex-row-reverse">
              <v-btn
                color="success"
                :disabled="!edit"
                @click="submitShain('給与情報', 'shainformwithrestriction')"
              >
                完了
              </v-btn>
            </div>
          </v-tab-item>
          <v-tab-item>
            <v-autocomplete
              v-model="shainExp.dayOver"
              :items="dayOverList"
              label="残業計算(日)"
              prepend-icon="mdi-account-cog"
              outlined
              item-text="name"
              item-value="code"
            ></v-autocomplete>
            <v-autocomplete
              v-model="shainExp.weekWorkStart"
              :items="weekWorkStartList"
              label="残業計算(週)"
              prepend-icon="mdi-account-cog"
              outlined
              item-text="name"
              item-value="code"
            ></v-autocomplete>
            <v-autocomplete
              v-model="shainExp.flexflag"
              :items="flexFlagList"
              label="残業計算(月)"
              prepend-icon="mdi-account-cog"
              outlined
              item-text="name"
              item-value="code"
            ></v-autocomplete>
            <div class="d-flex flex-row-reverse">
              <v-btn
                color="success"
                :disabled="!edit"
                @click="submitShainExpansion('時間計算')"
              >
                完了
              </v-btn>
            </div>
          </v-tab-item>
          <v-tab-item>
            <Btn
              color="info"
              icon="plus"
              text
              @click="addShozoku()"
              :disabled="!edit"
              >所属追加</Btn
            >
            <table class="my-5">
              <thead>
                <tr>
                  <th v-for="(header, i) in subHeaders" :key="i">
                    {{ header }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in this.shainExp.subDetails"
                  :key="index"
                >
                  <td>{{ item.displayName }}</td>
                  <td>
                    <v-text-field
                      v-model.number="item.amount1"
                      type="number"
                      step="1"
                      min="0"
                      suffix="円"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      v-model.number="item.amount2"
                      type="number"
                      step="1"
                      min="0"
                      suffix="円"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      v-model.number="item.amount3"
                      type="number"
                      step="1"
                      min="0"
                      suffix="円"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-checkbox v-model="item.subSpecialCommit"></v-checkbox>
                  </td>
                  <td>
                    <v-icon
                      small
                      class="ml-1"
                      color="error"
                      :disabled="!edit"
                      @click="deleteAction(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="d-flex flex-row-reverse">
              <v-btn
                color="success"
                :disabled="!edit"
                @click="submitShainExpansion('所属別設定')"
              >
                完了
              </v-btn>
            </div>
          </v-tab-item>
          <v-tab-item>
            <label>最終更新日</label>
            <table class="my-5">
              <thead>
                <tr>
                  <th v-for="(header, i) in subHeadersForLastedUpdate" :key="i">
                    {{ header }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in this.shainExpWorkList" :key="index">
                  <!--<td>{{ item.displayName }}</td>-->
                  <td>
                    <label>{{ dayKanjiList[Number(item.weekday) - 1] }}</label>
                  </td>
                  <td>
                    <v-autocomplete
                      v-model="item.shiftPatternId"
                      :items="shiftList"
                      outlined
                      item-text="shiftPatternName"
                      :item-value="item => Number(item.shiftPatternId)"
                    ></v-autocomplete>
                  </td>
                  <td>
                    <label>{{
                      getStartTime(item.shiftPatternId) +
                        getEndTime(item.shiftPatternId)
                    }}</label>
                  </td>
                  <td>
                    <label>{{ getBreakTime(item.shiftPatternId) }}</label>
                  </td>
                  <td>
                    <label>{{
                      getPlannedWorkingHours(item.shiftPatternId)
                    }}</label>
                  </td>
                  <td>
                    <v-row class="align-center">
                      <v-checkbox
                        v-model="item.week1"
                        :label="`１週 `"
                      ></v-checkbox>
                      <v-checkbox
                        v-model="item.week2"
                        :label="`２週 `"
                      ></v-checkbox>
                      <v-checkbox
                        v-model="item.week3"
                        :label="`３週 `"
                      ></v-checkbox>
                      <v-checkbox
                        v-model="item.week4"
                        :label="`４週 `"
                      ></v-checkbox>
                      <v-checkbox
                        v-model="item.week5"
                        :label="`５週 `"
                      ></v-checkbox>
                    </v-row>
                  </td>
                  <td>
                    <v-icon
                      small
                      @click="addShift(item)"
                      class="ml-1"
                      color="success"
                      :disabled="!edit"
                    >
                      mdi-plus
                    </v-icon>
                    <v-icon
                      small
                      @click="removeShift(item)"
                      class="ml-1"
                      color="error"
                      :disabled="!edit"
                    >
                      mdi-minus
                    </v-icon>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="d-flex flex-row-reverse">
              <v-btn
                color="success"
                :disabled="!edit"
                @click="submitShainExpansionWork('現状届')"
              >
                完了
              </v-btn>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </template>
    </v-form>
  </div>
</template>

<script>
import Common from "@/mixins/common";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";
import ShozokuAddDialog from "@/views/dialogs/ShozokuAddDialog.vue";
//import ShainExp from "@/models/ShainExp";

const SelectsDelete = {
  Delete: "0",
  Default: "1"
};

export default {
  name: "ShainDetails",
  mixins: [Common, Forms, Api, ShowDialogs],
  props: {
    title: String,
    prosess: String,
    items: Array,
    args: Object
  },
  data() {
    return {
      params: null,
      add: false,
      edit: false,
      show: false,
      restriction: false,
      enableField: [2, 3],
      tabs: 0, // Opens Tab 1 by default. 0-indexed.
      tabItems: ["基本情報", "給与情報", "時間計算", "所属別設定", "現状届"],
      subHeaders: [
        "所属名",
        "時給単価",
        "通勤単価",
        "特殊通勤単価",
        "特殊通勤",
        "actions"
      ],
      subHeadersForLastedUpdate: [
        "曜日",
        "シフト",
        "シフト時間",
        "シフト休憩",
        "予定勤務",
        "週",
        "actions"
      ],
      dayKanjiList: ["日", "月", "火", "水", "木", "金", "土"],
      shozokus: [],
      shainExp: {
        dayOver: "",
        weekWorkStart: "",
        flexflag: "",
        subDetails: []
      },
      shiftPatternDeleteList: [],
      startTimeFormat: "",
      endTimeFormat: "",
      breakTimeFormat: "",
      plannedWorkingHours: "",
      shainExpWorkList: [],
      shiftList: [],
      SelectsDelete,
      shain: {
        incode: "",
        code: "",
        name1: "",
        name2: "",
        furi: "",
        password: "",
        authority: "",
        kkubun: "",
        taimode: "",
        sex: "",
        shozoku: "",
        daybirth: "",
        daynyu: "",
        daytai: "",
        kyuhtan0: "",
        kyuhtan9: "",
        kyuhtan10: "",
        kyuhtan11: "",
        kyuhtan12: "",
        monthKbn: "",
        yearInfo: "",
        oldCode: null,
        isValid: SelectsDelete.Default
      },
      authorList: [],
      salaryList: [],
      zaiKbnList: [],
      sexList: [],
      shozokuList: [],
      dayOverList: [],
      weekWorkStartList: [],
      flexFlagList: [],
      //isNumber: value => Number.isInteger(value) || "整数で入力してください。",
      //isNull: value => value != null || "必須入力です。",
      Required: [value => value != null || "必須入力です"]
      //Required2: [value => 3 || "必須入力です"]
    };
  },
  computed: {
    Title() {
      return (
        this.params.title +
        " " +
        (this.add ? "追加" : this.edit ? "編集" : "詳細")
      );
    },
    canadd() {
      if (this.add === true) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    async checkPermissionForEdit() {
      const permission = Number(this.$store.state.authority);
      if (permission >= 5) {
        this.show = true;
      } else if ([1, 2].includes(permission)) {
        this.show = false;
        this.restriction = true;
      } else {
        await this.$warning("けんげんがありません");
        await this.back();
      }
    },
    async checkPermissionForAdd() {
      const permission = Number(this.$store.state.authority);
      if (permission >= 5) {
        this.show = true;
      } else {
        await this.$warning("けんげんがありません");
        await this.back();
      }
    },
    checkdaytai(param) {
      console.log("taimod", param);
      console.log("daff", !this.enableField.includes(this.shain.taimode));
      return !this.enableField.includes(this.shain.taimode);
    },
    getEdaNo(empWorkList, currentItem) {
      const filteredList = empWorkList.filter(
        item => item.weekday === currentItem.weekday
      );
      const newList = filteredList.map(item => {
        return item.edaNo;
      });
      console.log("filer", newList);
      for (let i = 0; i <= newList.length; i++) {
        if (!newList.includes(i)) {
          return i;
        } else if (i === filteredList.length) {
          return i;
        } else {
          continue;
        }
      }
    },
    removeShift(item) {
      this.shainExpWorkList = this.shainExpWorkList.filter(t => t !== item);
      this.shiftPatternDeleteList.push(item);
    },
    addShift(item) {
      const json = {
        shiftPatternId: item.shiftPatternId,
        incode: this.shain.incode,
        affiliationId: this.shain.shozoku,
        week1: 1,
        week2: 1,
        week3: 1,
        week4: 1,
        week5: 1,
        startDate: new Date().toISOString().slice(0, 10),
        weekday: item.weekday,
        edaNo: this.getEdaNo(this.shainExpWorkList, item)
      };
      console.log(
        "weekdayadd",
        json,
        this.getEdaNo(this.shainExpWorkList, item)
      );
      this.shainExpWorkList.push(json);
      this.shainExpWorkList.sort(
        (a, b) => Number(a.weekday) - Number(b.weekday)
      );
      console.log("sortfedwor", this.shainExpWorkList);
    },
    async validate() {
      const { valid } = await this.$refs.form.validate();

      if (valid) alert("Form is valid");
    },
    getFormatedShainExpansionWork(param) {
      let result = [];
      result = param.map(
        ({
          incode,
          affiliationId,
          startDate,
          weekday,
          week1,
          week2,
          week3,
          week4,
          week5,
          createUser,
          createTs,
          updateUser,
          updateTs,
          shiftPatternId,
          edaNo
        }) => {
          return {
            incode,
            affiliationId,
            startDate,
            weekday,
            week1: Number(week1),
            week2: Number(week2),
            week3: Number(week3),
            week4: Number(week4),
            week5: Number(week5),
            createUser,
            createTs,
            updateUser,
            updateTs,
            shiftPatternId,
            edaNo
          };
        }
      );
      return result;
    },
    getShift(param) {
      let result = [];
      result = param.map(({ shift }) => {
        return {
          shift
        };
      });
      return result;
    },
    getStartTime(id) {
      const details = this.shiftList.find(
        ({ shiftPatternId }) => shiftPatternId === id
      );
      if (details === undefined) {
        return "";
      } else return details.startTimeFormat + " ~ ";
    },
    addDay(param) {
      for (let i = 1; i <= 7; i++) {
        const details = param.find(({ weekday }) => weekday === i.toString());
        console.log("sdsf", details);
        if (details === undefined) {
          const json = {
            shiftPatternId: 0,
            incode: this.shain.incode,
            affiliationId: this.shain.shozoku,
            week1: 1,
            week2: 1,
            week3: 1,
            week4: 1,
            week5: 1,
            startDate: new Date().toISOString().slice(0, 10),
            weekday: i.toString(),
            edaNo: 0
          };
          this.shainExpWorkList.push(json);
        }
      }
      this.shainExpWorkList.sort(
        (a, b) => Number(a.weekday) - Number(b.weekday)
      );
    },
    getEndTime(id) {
      const details = this.shiftList.find(
        ({ shiftPatternId }) => shiftPatternId === id
      );
      if (details === undefined) {
        return "";
      } else return details.endTimeFormat;
    },
    getBreakTime(id) {
      const details = this.shiftList.find(
        ({ shiftPatternId }) => shiftPatternId === id
      );
      if (details === undefined) {
        return "";
      } else return details.breakTimeFormat;
    },
    getPlannedWorkingHours(id) {
      const details = this.shiftList.find(
        ({ shiftPatternId }) => shiftPatternId === id
      );
      if (details === undefined) {
        return "";
      } else return details.plannedWorkingHours;
    },
    async getShainShift() {
      const query = { affiliationId: this.shain.shozoku };
      const encode = encodeURI(JSON.stringify(query));
      const data = await this.$get(
        this.Paths.shiftpattern + "/list",
        "query=" + encode
      );
      this.shiftList = data;
      //console.log("shiftlist", this.shiftList);
    },
    async getShainExpansionWork() {
      const query = { shainIncode: this.shain.incode };
      const encode = encodeURI(JSON.stringify(query));
      const data = await this.$get(this.Paths.shainExpWork, "query=" + encode);
      //console.log("sdfdg", this.getFormatedShainExpansionWork(data));
      this.shainExpWorkList = this.getFormatedShainExpansionWork(data);
      this.addDay(this.shainExpWorkList);
      console.log("shainexpworklist", this.shainExpWorkList);
      console.log("shiftlist", this.shiftList);
    },
    async callShainSubmit(message) {
      this.$loading();
      try {
        const param = this.shain;

        //console.log("submit_param", param);
        if (this.add) {
          await this.$post(this.Paths.shain, param);
        } else {
          await this.$put(this.Paths.shain, param);
        }
        this.edit = false;
        return await this.$info("更新しました。", message);
        //this.$refs.form.reset();
      } catch (e) {
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async submitShain(message, form) {
      if (form === "shainformwithrestriction") {
        console.log(form);
        //if (!this.$refs.shainformwithrestriction.validate())
        //return alert("必須入力です");
        //else
        await this.callShainSubmit(message);
        await this.logout();
      } else {
        if (form === "shainform") {
          console.log(form);
          if (!this.$refs.shainform.validate()) return alert("必須入力です");
          else this.callShainSubmit(message);
        } else return;
      }
    },
    async postRecord(param) {
      await this.$post(this.Paths.shainExp, param);
      this.add = false;
    },
    async submitShainExpansion(message) {
      console.log("submit");
      this.$loading();
      try {
        this.shainExp.incode = this.shain.incode;
        this.shainExp.subDetails.forEach(e => {
          e.subSpecialCommit = Number(e.subSpecialCommit);
        });
        //console.log("submit_param", this.shainExp);
        await this.postRecord(this.shainExp);
        this.edit = false;
        await this.$info("更新しました。", message);
      } catch (e) {
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async submitShainExpansionWork(message) {
      console.log("submit");
      this.$loading();
      try {
        const requests = this.getFormatedShainExpansionWork(
          this.shainExpWorkList
        );
        //console.log("submit_param", requests);
        await this.$put(this.Paths.shainExpWork, requests);
        //this.add = false;
        this.edit = false;
        await this.$info("更新しました。", message);
        //this.$router.push({ name: "ShainDetails", params });
        //window.location.reload();
      } catch (e) {
        this.$error(e.message);
      } finally {
        this.$unloading();
        //this.refresh();
      }
    },
    async deleteAction(item) {
      console.log("deleteAction", item);
      const confirm = await this.$deleteConfirm(
        "削除します。\nよろしいですか？"
      );
      console.log(confirm);
      if (!confirm) return;

      this.shainExp.subDetails = this.shainExp.subDetails.filter(
        e => e !== item
      );
    },
    async addShozoku() {
      console.log("addShozoku");
      const param = { shozokus: this.shozokus };
      const selectShozokus = await this.$showDialog(ShozokuAddDialog, {
        args: param
      });

      if (!selectShozokus) return;
      console.log("selectShozokus", selectShozokus);
      selectShozokus.forEach(s => {
        const f = this.shainExp.subDetails.filter(
          e => String(e.affiliationId) === s.code
        );
        if (f.length == 0) {
          this.shainExp.subDetails.push({
            affiliationId: s.code,
            displayName: s.name,
            amount1: "0",
            amount2: "0",
            amount3: "0",
            subSpecialCommit: false
          });
        }
      });
    },
    //Listに戻る
    async back() {
      const path = "/list/shain";
      await this.$router.push({
        path: path,
        query: { filter: this.params.filter }
      });
    },
    async logout() {
      const path = "/login";
      await this.$router.push({
        path: path
      });
    },
    refresh() {
      //const path = "/details/" + "shain";
      const path = "/details/shain";
      const params = {
        title: this.title,
        //prosess,
        //items: this.filter.items,
        filter: this.filter,
        records: this.items
      };
      //const pathname = await this.getDetailPath();
      console.log(path, params);

      this.$router.push({ path: path, params });
    },
    //#region GetAPI
    async getAuthorList() {
      console.log("getAuthorList");
      const params = { kbn: "AUTH" };
      return await this.$get(
        this.Paths.codeMasterPulldown,
        "query=" + encodeURI(JSON.stringify(params))
      );
    },
    async getSalaryList() {
      console.log("getSalaryList");
      const params = { kbn: "SALARY" };
      return await this.$get(
        this.Paths.codeMasterPulldown,
        "query=" + encodeURI(JSON.stringify(params))
      );
    },
    async getZaiKbnList() {
      console.log("getZaiKbnList");
      const params = { kbn: "ZAIKBN" };
      return await this.$get(
        this.Paths.codeMasterPulldown,
        "query=" + encodeURI(JSON.stringify(params))
      );
    },
    async getSexList() {
      console.log("getSexList");
      const params = { kbn: "SEX" };
      return await this.$get(
        this.Paths.codeMasterPulldown,
        "query=" + encodeURI(JSON.stringify(params))
      );
    },
    async getDayOverList() {
      console.log("getDayOverList");
      const params = { kbn: "OVDAY" };
      return await this.$get(
        this.Paths.codeMasterPulldown,
        "query=" + encodeURI(JSON.stringify(params))
      );
    },
    async getWeekWorkStartList() {
      console.log("getWeekWorkStartList");
      const params = { kbn: "WWS" };
      return await this.$get(
        this.Paths.codeMasterPulldown,
        "query=" + encodeURI(JSON.stringify(params))
      );
    },
    async getFlexFlagList() {
      console.log("getFlexFlagList");
      const params = { kbn: "EXISTE" };
      return await this.$get(
        this.Paths.codeMasterPulldown,
        "query=" + encodeURI(JSON.stringify(params))
      );
    },
    async getShozokuList() {
      console.log("getShozokuList");
      return await this.$get(this.Paths.shozokuFilter);
    }
    //#endregion
  },
  async created() {
    console.log(this.name, "createdshain");
    const params = this.$route.params;
    const prosess = params.prosess;
    this.params = params;
    console.log("filtereee", this.params.records);

    this.$loading();
    try {
      if (prosess && typeof prosess === "string")
        this.params.prosess = prosess.toLowerCase();

      switch (this.params.prosess) {
        case "add":
          this.add = true;
          this.edit = true;
          break;
        case "edit":
          this.add = false;
          this.edit = true;
          break;
        default:
          break;
      }
      const promiseList = [
        this.getAuthorList(),
        this.getSalaryList(),
        this.getZaiKbnList(),
        this.getSexList(),
        this.getShozokuList(),
        this.getDayOverList(),
        this.getWeekWorkStartList(),
        this.getFlexFlagList()
      ];
      const [
        auth,
        salary,
        zaikbn,
        sex,
        shozoku,
        dayOver,
        weekWorkStart,
        flexFlag
      ] = await Promise.all(promiseList);

      this.authorList = auth;
      this.salaryList = salary;
      this.zaiKbnList = zaikbn;
      this.sexList = sex;
      this.shozokuList = shozoku;
      this.dayOverList = dayOver;
      this.weekWorkStartList = weekWorkStart;
      this.flexFlagList = flexFlag;
      this.params.filter.items.forEach(e => {
        if (e.code === "shozoku") {
          this.shozokus = e.models;
        }
      });

      if (!this.add) {
        const args = this.args;
        this.shain = args;
        console.log("www", this.shain);
        const query = { shainIncode: this.shain.incode };
        const encode = encodeURI(JSON.stringify(query));
        const data = await this.$get(this.Paths.shainExp, "query=" + encode);
        this.shainExp = data.items[0];
        this.getShainExpansionWork();
        this.getShainShift();
        this.checkPermissionForEdit();
        //if (this.$store.state.authority >= 5) this.restriction = false;
        //else this.restriction = true;
        //console.log("promiseddd",data.items[0],data.name,this.shainExp,encode);
      } else {
        //this.$warning("けんげんがありません");
        //this.back();
        this.checkPermissionForAdd();
      }
    } catch (e) {
      this.$error(e.message);
    } finally {
      this.$unloading();
    }
  }
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

table th {
  text-align: start !important;
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  width: 5%;
  padding: 10px 5px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75rem;
}

table td {
  text-align: start !important;
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  border-top: none;
  width: 5%;
  padding: 10px 5px;
  font-size: 0.875rem;
}
</style>
